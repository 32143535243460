import React from 'react';
import { Button, Grid, Typography, withStyles } from '@material-ui/core';
import { AddCircle } from '@material-ui/icons';
import classNames from 'classnames';
import { FieldArray } from 'react-final-form-arrays';
import { ExpansionPanel, OutlinedDatePicker, OutlinedTextField } from '../../../../forms';
import { FixedWidthFileUpload } from '../../../../forms/form-components/fixed-width-file-upload';
import { requiredValidator } from '../../../../forms/validations';
import {
    FirstNameMask,
    InitialsMask,
    LastNameMask,
    TelephoneNumberMask,
} from '../../../../forms/input-masks';
import styles from '../../../../styles/styles';
import { convertToUpper } from '../../../../shared/helper-functions';
import { DeceasedNavigation } from '../../../common/navigation/deceased-navigation';
import { DoctorsAddress } from './doctors-address';

const doctorVisits = ({
    classes,
    handleBack,
    index,
    editable,
    handleBackStepper,
    handleNext,
    stepsLength,
    form,
}) => {
    const { push } = form.mutators;
    const prefix = 'doctorVisits';
    return (
        <React.Fragment>
            <Grid item xs={12}>
                <Typography className={classes.utilSteps}>
                    Step {index + 1} of {stepsLength}
                </Typography>
            </Grid>
            <Grid item xs={12} container justify="flex-start">
                <Typography className={classes.activeStepperCircle}>{index + 1}</Typography>
                <Typography className={classes.stepHeader}>Doctor's Visits</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.description}>
                    Please add the details of every doctor who attended to the deceased in the five
                    years before their death (need only be completed for policy with life cover).
                </Typography>
            </Grid>
            <Grid item xs={12}>
                <OutlinedDatePicker
                    name="deceasedDetails.causeOfDeath.firstConsulation"
                    label="Date deceased first consulted a doctor for their last illness"
                    disabled={!editable}
                    disableFuture
                />
            </Grid>
            <Grid item xs={12}>
                <Typography className={classes.doctorsVisitTitle}>
                    Doctor’s and Practice Details
                </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginLeft: '16px' }}>
                <FieldArray name={`${prefix}`}>
                    {({ fields }) =>
                        fields.map((doctor, doctorIndex) => {
                            return doctorsVisitRow(
                                editable,
                                prefix,
                                fields,
                                classes,
                                form,
                                doctorIndex
                            );
                        })
                    }
                </FieldArray>
            </Grid>
            {editable && (
                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        id="add-visit"
                        className={classNames(classes.customButton, classes.fullWidthButton)}
                        onClick={() => push(`${prefix}`, { occurences: [{}] })}
                    >
                        <AddCircle style={{ marginRight: '8px' }} />
                        Add Doctor
                    </Button>
                </Grid>
            )}
            <Grid item xs={12}>
                <FixedWidthFileUpload
                    name="extractOfMedicalHistory"
                    helperText="Personal medical attendant's report, if a policy is less than 3 years"
                    readonly={!editable}
                />
            </Grid>
            <Grid item xs={12} className={classes.navigationGroup}>
                <DeceasedNavigation
                    form={form}
                    handleBack={handleBack}
                    handleBackStepper={handleBackStepper}
                    handleNext={handleNext}
                    prefix="deceased-doctors"
                />
            </Grid>
        </React.Fragment>
    );
};

function doctorsVisitRow(editable, prefix, fields, classes, form, doctorIndex) {
    const { push } = form.mutators;
    if (fields.length === 0) {
        fields.push(`${prefix}`, undefined);
    }

    return (
        <ExpansionPanel
            key={`${prefix}${doctorIndex + 1}`}
            name={`doctor${doctorIndex + 1}`}
            id={`doctor${doctorIndex + 1}`}
            className={classes.expensionPanel}
            style={{ marginLeft: '-16px', marginTop: '16px' }}
            defaultExpanded={true}
            remove={() => fields.remove(doctorIndex)}
            deletable={editable}
        >
            <Grid container>
                <Grid item xs={12}>
                    <Typography style={{ marginTop: '30px' }} className={classes.expansionSubTile}>
                        Doctor's Details
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <OutlinedTextField
                        label="Doctor's Initials"
                        name={`${prefix}[${doctorIndex}].doctorsInitials`}
                        mask={InitialsMask}
                        parse={convertToUpper}
                        placeholder="Enter initials"
                        disabled={!editable}
                        className={classes.expensionPanelComponents}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedTextField
                        label="Doctor's Name"
                        name={`${prefix}[${doctorIndex}].doctorsName`}
                        mask={FirstNameMask}
                        placeholder="Enter name"
                        disabled={!editable}
                        className={classes.expensionPanelComponents}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedTextField
                        label="Doctor's Surname"
                        name={`${prefix}[${doctorIndex}].doctorsSurname`}
                        validate={requiredValidator}
                        mask={LastNameMask}
                        placeholder="Enter surname"
                        labelprefixed={'true'}
                        disabled={!editable}
                        className={classes.expensionPanelComponents}
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedTextField
                        label="Contact Number"
                        name={`${prefix}[${doctorIndex}].doctorsContactDetails`}
                        placeholder="Enter contact number"
                        mask={TelephoneNumberMask}
                        disabled={!editable}
                        className={classes.expensionPanelComponents}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography style={{ marginTop: '24px' }} className={classes.expansionSubTile}>
                        Practice Details
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <DoctorsAddress
                        prefix={`${prefix}[${doctorIndex}].`}
                        index={doctorIndex}
                        editable={editable}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FieldArray
                        name={`${prefix}[${doctorIndex}].occurences`}
                        className={classes.expensionPanelComponents}
                    >
                        {({ fields }) =>
                            fields.map((visit, visitIndex) => {
                                return visitOccurenceRow(
                                    editable,
                                    prefix,
                                    fields,
                                    doctorIndex,
                                    visitIndex,
                                    classes
                                );
                            })
                        }
                    </FieldArray>
                </Grid>
                {editable && (
                    <Button
                        variant="contained"
                        className={classes.customButton}
                        onClick={() => push(`${prefix}[${doctorIndex}].occurences`, undefined)}
                        id={`add-occurence${doctorIndex}`}
                        style={{ marginLeft: '1px', width: '333px' }}
                    >
                        <AddCircle style={{ marginRight: '8px' }} />
                        Add Consultation
                    </Button>
                )}
            </Grid>
        </ExpansionPanel>
    );
}

function visitOccurenceRow(editable, prefix, fields, doctorIndex, visitIndex, classes) {
    if (fields.length === 0) {
        fields.push(`${prefix}[${doctorIndex}].occurences`, [{}]);
    }
    return (
        <ExpansionPanel
            key={`doctorsVisits${doctorIndex}occurence${visitIndex + 1}`}
            name={`visit${visitIndex + 1}`}
            id={`visit${visitIndex + 1}`}
            label={`Reason for consultation ${visitIndex + 1}`}
            className={classes.expensionPanel}
            style={{ marginLeft: '-16px', marginTop: '16px' }}
            remove={() => fields.remove(visitIndex)}
            deletable={editable}
        >
            <Grid container>
                <Grid item xs={12}>
                    <OutlinedDatePicker
                        className={classes.expensionPanelComponents}
                        name={`${prefix}[${doctorIndex}].occurences[${visitIndex}].dateOfAttendance`}
                        disabled={!editable}
                        validate={requiredValidator}
                        disableFuture
                    />
                </Grid>
                <Grid item xs={12}>
                    <OutlinedTextField
                        className={classes.expensionPanelComponents}
                        name={`${prefix}[${doctorIndex}].occurences[${visitIndex}].reason`}
                        label="Reason"
                        placeholder="Enter reason"
                        disabled={!editable}
                        validate={requiredValidator}
                        multiline
                    />
                </Grid>
            </Grid>
        </ExpansionPanel>
    );
}

export const DoctorVisits = withStyles(styles)(doctorVisits);
