import React from 'react';

import {  Typography, withStyles } from '@material-ui/core';
import styles from '../../styles/styles';
import { ClaimStepper } from '../claim/claim-stepper';
import { HorizontalStepper } from '../common/horizontal-stepper';
import { Introduction } from './introduction';
import { ClaimTypes } from '../../shared/lookups';
import { ClaimantDetails } from './claimant-details/claimant-details';
import { ApplicationProcess } from './application-process';
import informationIcon from '../../styles/icons/information.svg';
import { PolicyOwnerDetails } from '../living-lifestyle/policy-holder/policyowner-details';
import { LifeAssuredDetails } from './life-assured/life-assured-details';
import { PaymentDetails } from './payment/payment-details'
import { getSouthAfricanAddress } from '../../shared/helper-functions';
import { Summary } from './summary/summary';
import { Declaration } from './declaration/declaration';

export const lifeStyleComponents = [
    {
        name: 'Introduction',
        information:
            'We outline the documents we need to process your claim, as well as any additional document(s) which might be relavant.',
        component: Introduction,
    },
    {
        name: 'Claimant Details',
        information: 'We will need personal details about the Claimant.',
        component: ClaimantDetails,
    },
    {
        name: 'Policyholder',
        information: 'We will require personal details about the Policy Owner.',
        component: PolicyOwnerDetails,
    },
    {
        name: 'Life Assured',
        information:
            'We will need details about the Life Assured.',
        component: LifeAssuredDetails,
    },
    {
        name: 'Payment',
        information:
            'We will need details about the Payments.',
        component: PaymentDetails,
    },
    {
        name: 'Summary',
        information: 'This is a chance to review everything before you submit.',
        component: Summary,
    },
    {
        name: 'Declaration',
        information: 'We will need you to confirm that you agree with our terms and conditions.',
        component: Declaration,
    },
];

function claimHeader({ classes, activeStepIndex, components, stepThrough, updateStepIndexAll }) {
    if (activeStepIndex === 0) {
        return null;
    }

    return (
        <React.Fragment>
            <ApplicationProcess sectionIndex={activeStepIndex} />
            <HorizontalStepper
                components={components}
                activeIndex={activeStepIndex}
                stepThrough={stepThrough}
                updateIndex={updateStepIndexAll}
            />
            <SectionNotification
                classes={classes}
                activeStepIndex={activeStepIndex}
                length={components.length}
            />
        </React.Fragment>
    );
}

function SectionNotification({ classes, activeStepIndex, length }) {
    if (activeStepIndex === length - 1) {
        return null;
    }

    const notification =
        activeStepIndex === 5
            ? 'Please check and ensure all the information you provided is correct. You may also print this for your records.'
            : 'Please complete all fields marked with a *';

    return (
        <div className={classes.requiredInformation}>
            <img src={informationIcon} alt="information" />
            <Typography className={classes.requiredInformationText}>{notification}</Typography>
        </div>
    );
}

export function validateLivingLifeStyleClaim(form) {
    const policyHolderDetails = form.policyholder || {};
    const existingIndividual = policyHolderDetails.individual || {};
    const existingAddressDetails = existingIndividual.addressDetails || getSouthAfricanAddress();
    const existingLifeAssured = form.lifeAssured || {};
    const lifeAssuredAddressDetails = existingLifeAssured.addressDetails || getSouthAfricanAddress();

    return {
        ...form,
        policyholder: {
            ...policyHolderDetails,
            individual: {
                ...existingIndividual,
                addressDetails: {
                    ...existingAddressDetails,
                }
            },
        },
        lifeAssured: {
            ...existingLifeAssured,
            addressDetails: {
                ...lifeAssuredAddressDetails
            }
        }
    };
}

const defaultInitialValues = { claimType: ClaimTypes.LivingLifestyleClaim };

export const LivingLifeStyleClaim = withStyles(styles)(() => {
    return <ClaimStepper defaultInitialValues={defaultInitialValues} headerComponent={claimHeader} />;
}
);
